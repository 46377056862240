import { useEffect } from 'react';
import { useBlockchain } from '../../context/blockchain-context';
// import { useModal } from '../../context/modal-context';
// import { ConfirmDepositModal } from '../confirm-deposit-modal/confirm-deposit-modal';
// import { useTranslation } from 'react-i18next';
import { useWebsocket } from '../../context/websocket-context';

export const UserBalance = () => {
  const { proxyWalletSetup, setShowConfirmDepositHeading } = useBlockchain();
  // const { openModal } = useModal();
  // const { t } = useTranslation();

  const { usdcBalance } = useWebsocket();
  useEffect(() => {
    if ((usdcBalance?.value ?? 0) > 0 && proxyWalletSetup) {
      // setShowConfirmDepositHeading(true);
      // openModal({
      //   content: <ConfirmDepositModal />,
      //   title: t`Confirm Deposit`,
      // });
    } else {
      setShowConfirmDepositHeading(false);
    }
  }, [usdcBalance?.value]);

  return <></>;
};
